<template>
    <div>
        <router-view></router-view>
        <MixinComponent
            ref="mixinComponentRef"
        />
        <AuthComponent
            :key="authComponentKey"
            @reRenderComponent="authComponentKey += 1"
            @showSuccessDialog="showSuccessDialog"
            @showErrorDialog="showErrorDialog"
            @showNotificationDialog="showNotificationDialog"
        />
    </div>
</template>

<script>

import AuthComponent from '@/components/auth/AuthComponent.vue';
import MixinComponent from '@/components/MixinComponent.vue';

export default {
    name: 'App',
    components: {
        MixinComponent,
        AuthComponent,
    },
    data: () => ({
        authComponentKey: 0,
    }),
    mounted() {
        if (window.navigator.userAgent.toLowerCase().includes('android') && !sessionStorage.getItem("intentRedirected")) {
            const path = location.href.replace(/^https?:\/\//, '');
            const protocol = location.protocol.replace(':', '')
            sessionStorage.setItem("intentRedirected", "true")
            window.location.href = "intent://" + path + "#Intent;scheme=" + protocol + ";package=com.android.chrome;end;";
        }
    },
    methods: {
        showSuccessDialog() {
            this.$refs.mixinComponentRef.showSuccessDialog();
        },
        showErrorDialog(content) {
            this.$refs.mixinComponentRef.showErrorDialog(content);
        },
        showNotificationDialog() {
            this.$refs.mixinComponentRef.showNotificationDialog();
        },
    },
};
</script>

<style scoped>

</style>
